import { graphql, Link, PageProps } from "gatsby";
import { GatsbySeo, GatsbySeoProps } from "gatsby-plugin-next-seo";
import * as React from "react";

import { Layout } from "@/components/Layout";

const BookPage: React.FC<PageProps<Queries.BookListQuery>> = ({ data }) => {
  const title = "Book";
  const description = "Boctozで紹介した書籍の一覧です";

  const seoProps: GatsbySeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      url: `https://boctoz.com/book/`,
    },
  };

  return (
    <Layout>
      <GatsbySeo {...seoProps} />
      <div className="prose max-w-full pt-12 pb-8">
        <h1 className="md:text-6xl">Book</h1>
        <p>{description}</p>
      </div>
      <div className="prose">
        <ul>
          {data.allContentfulBook.nodes.map((node) => (
            <li>
              <Link to={`/book/${node.id}`}>{node.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BookList {
    allContentfulBook {
      nodes {
        id
        image {
          gatsbyImageData(formats: WEBP, placeholder: BLURRED, width: 200)
        }
        title
      }
    }
  }
`;

export default BookPage;
